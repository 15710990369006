'use client'

import { createContext, Dispatch, useState, useEffect, ReactNode } from 'react'

import { usePathname } from 'next/navigation'

import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { Analytics } from '@vercel/analytics/react'
import { SnackbarProvider } from 'notistack'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'

//import createEmotionServer from '@emotion/server/create-instance'
//import { limitedFetcher20 } from 'app/libs/fetcher';
import theme from '@/app/theme'

import createEmotionCache from './createEmotionCache'
/*
import 'app/styles/globals.cass';
import 'box-ui-elements/dist/uploader.css';
import 'box-ui-elements/dist/explorer.css';
import 'box-ui-elements/dist/preview.css';
*/
//ハンバーガーメニューが開いているかどうかのuseContext
export const OpenContext = createContext(
  {} as {
    open: boolean
    setOpen: Dispatch<React.SetStateAction<boolean>>
  },
)

// https://zenn.dev/ttani/articles/next-materialui-setup
export const Provider = ({ children }) => {
  const [open, setOpen] = useState(true)
  /*

  useEffect(() => {
    const savedOpen = localStorage.getItem('drawerOpen')
    if (savedOpen !== null) {
      setOpen(JSON.parse(savedOpen))
    }
  }, [])
  useEffect(() => {
    localStorage.setItem('drawerOpen', JSON.stringify(open))
  }, [open])
*/
  return (
    <SnackbarProvider maxSnack={3}>
      <OpenContext.Provider value={{ open, setOpen }}>{children}</OpenContext.Provider>
    </SnackbarProvider>
  )
}
