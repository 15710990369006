'use client'
import { createTheme } from '@mui/material/styles'
//import { extendTheme } from '@mui/material/styles';
//import { CssVarsProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      // メインカラー（青）
      main: '#008DDE',
    },
    secondary: {
      // サイドバーの背景など
      main: '#F5F8FA',
      // borderなどに使用
      light: '#E0E0E0',
      // テキストの色（灰色）
      dark: '#BDBDBD',
    },
    info: {
      // 背景などに使用
      main: '#3FB9FF',
      // borderなどに使用
      light: '#DFF3FF',
      // アイコンの色など
      dark: '#008DDE',
    },
    error: {
      main: '#CB3A31',
      light: '#FFF4F2',
    },
    common: {
      white: '#FFF',
      // 全体のテキストの色
      black: '#001F31',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1025,
      xl: 1536,
    },
  },
})
export default theme

export const userTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      // メインカラー（青）
      main: '#008DDE',
    },
    secondary: {
      // サイドバーの背景など
      main: '#F5F8FA',
      // borderなどに使用
      light: '#E0E0E0',
      // テキストの色（灰色）
      dark: '#BDBDBD',
    },
    info: {
      // 背景などに使用
      main: '#3FB9FF',
      // borderなどに使用
      light: '#DFF3FF',
      // アイコンの色など
      dark: '#008DDE',
    },
    error: {
      main: '#CB3A31',
      light: '#FFF4F2',
    },
    common: {
      white: '#FFF',
      // 全体のテキストの色
      black: '#001F31',
    },
  },
})
