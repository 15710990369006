import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/vercel/path0/src/app/Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/theme.ts");
